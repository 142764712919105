import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from 'gatsby-image'
import { Section, SectionPageTitle, BreadCrumb, MarkdownContent, } from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';

const BackgroundBg = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`

const ContentPage = ({ data, location }) => {
	const pageData = data.contentfulContentPage
	const PostContent = HTMLContent || Content;
	return(
		<Layout location={location}>
      <SEO title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section pt="140px" pb="70px">
      	<BackgroundBg>
      		<Img fluid={pageData.heroBackground.fluid} />
      	</BackgroundBg>
        <div className="container">
          <BreadCrumb><Link to='/'>Home</Link>/<span>{pageData.title}</span></BreadCrumb>
          <SectionPageTitle mt="30px" mb="0px" textAlign="center">{pageData.title}</SectionPageTitle>
        </div>
      </Section>
      <Section pt="70px" pb="45px">     
        <div className="container">
          <MarkdownContent maxWidth="890px">
              <PostContent
                content={generateHTML(pageData.content.childMarkdownRemark.html)}
              />
          </MarkdownContent>
        </div>
      </Section>
    </Layout>
	)
}

export default ContentPage
export const pageQuery = graphql`
  query ContentPageQuery($id: String!) {
    contentfulContentPage(id: { eq: $id }) {
      metaTitle
      metaDescription
      title
      heroBackground {
        fluid {
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`